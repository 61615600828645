import request from '@/utils/request'

// 查询政治面貌选项
export function findAuditoriumServicePoliticalOutlookOptions() {
  return request({
    url: `/auditorium_services/political_outlook_options`,
    method: 'get'
  })
}

// 查询子女戴号
export function findAuditoriumServiceDaiHaoOptions() {
  return request({
    url: `/auditorium_services/dai_hao_options`,
    method: 'get'
  })
}

// 查询棺材来源选项
export function findAuditoriumServiceCoffinTypeOptions() {
  return request({
    url: `/auditorium_services/coffin_type_options`,
    method: 'get'
  })
}

// 查询寿衣
export function findAuditoriumServiceShroudTypeOptions() {
  return request({
    url: `/auditorium_services/shroud_options`,
    method: 'get'
  })
}
//查询骨灰盒
export function findAuditoriumServiceCineraryTypeOptions() {
  return request({
    url: `/auditorium_services/cinerary_options`,
    method: 'get'
  })
}

// 查询佛事
export function findAuditoriumServiceBuddhistTypeOptions() {
  return request({
    url: `/auditorium_services/buddhist_options`,
    method: 'get'
  })
}

// 查询大巴
export function findAuditoriumServiceBusTypeOptions() {
  return request({
    url: `/auditorium_services/bus_options`,
    method: 'get'
  })
}
// 遗像来源
export function findAuditoriumServiceDeathPhotoTypeOptions() {
  return request({
    url: `/auditorium_services/death_photo_options`,
    method: 'get'
  })
}

// 风俗类型
export function findAuditoriumServiceSuitcaseTypeOptions() {
  return request({
    url: `/auditorium_services/suitcase_type_options`,
    method: 'get'
  })
}

