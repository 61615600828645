<template>
  <a-row>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="礼厅服务交接"
      width="1400px"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item :wrapper-col="{ span: 9 }"><div style="text-align: center">请与客户仔细沟通以下交接事项：</div></a-form-item>
        <a-row>
          <a-col :span="12">
            <a-form-item label="厅房名称">
              {{ serviceOrder.hall_name? serviceOrder.hall_name:'' }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="服务套餐">
              {{ serviceOrder.package_name? serviceOrder.package_name:'' }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="进出厅时间">
              {{ `${handover.to_hall_time? handover.to_hall_time:''}~${handover.out_hall_time? handover.out_hall_time:''}` }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="逝者信息">
              {{ `${serviceOrder.death_name? serviceOrder.death_name:''}-${serviceOrder.sex_display? serviceOrder.sex_display:''}-${serviceOrder.age? serviceOrder.age:''}` }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="政治面貌">
              <a-row>
                <a-col :span="16">
                  <a-select
                    v-decorator="['political_outlook_slug', {
                      rules: [
                        { required: true, message: `请选择政治面貌` }
                      ],
                    }]"
                    @change="(e) => {
                      if (e === 'zhong_gong_dang_yuan') {
                        isShowPartyFlag = true
                      } else {
                        isShowPartyFlag = false
                      }
                    }"
                  >
                    <a-select-option
                      v-for="(option, index) in politicalOutlookOptions"
                      :key="index"
                      :value="option.slug"
                    >
                      {{ option.label }}
                    </a-select-option>
                  </a-select>
                </a-col>
                <a-col :span="6" :offset="2">
                  <a-checkbox
                    v-if="isShowPartyFlag"
                    v-decorator="['is_need_party_flag']"
                    :checked="isNeedPartyFlag"
                    @change="(e) => {
                      form.setFieldsValue({is_need_party_flag: e.target.checked})
                      isNeedPartyFlag = e.target.checked
                    }"
                  >
                    党旗
                  </a-checkbox>
                </a-col>
              </a-row>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="风俗">
              <a-radio-group
                v-decorator="['suitcase_type', {
                  rules: [
                    { required: true, message: `请选择风俗` }
                  ],
                }]"
              >
                <a-radio
                  v-for="(item, index) in suitcaseTypeOptions"
                  :value="item.slug"
                  :key="index"
                >{{ item.label }}</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="是否换红">
              <a-radio-group
                v-decorator="['is_huan_hong', {
                  rules: [
                    { required: true, message: `请选择是否换红` }
                  ],
                }]"
              >
                <a-radio :value="true">是</a-radio>
                <a-radio :value="false">否</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="配偶情况">
              <a-radio-group
                v-decorator="['partner_status_slug', {
                  rules: [
                    { required: true, message: `请选择配偶情况` }
                  ],
                }]"
              >
                <a-radio value="living">健在</a-radio>
                <a-radio value="dead">不健在</a-radio>
                <a-radio value="none">无配偶</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="子女情况">
              <a-input
                style="width: 100%"
                placeholder="若无特别说明，请输入无"
                v-decorator="['children_info', {
                  rules: [
                    { required: true, message: '请输入子女情况' },
                    { max: 100, message: '最多100个字符' },
                  ]
                }]"
                autocomplete="off"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="子女戴号情况">
              <a-checkbox-group
                v-decorator="['children_dai_hao', {
                  rules: [
                    { required: true, message: '请输入子女戴号情况' },
                  ]
                }]"
                :options="daiHaoOptions"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="!formData.is_exist_shroud">
            <a-form-item label="寿衣">
              <a-radio-group
                v-decorator="['shroud_type_slug', {
                  rules: [
                    { required: true, message: `请选择寿衣` }
                  ],
                }]"
              >
                <a-radio
                  v-for="(item, index) in shroudOptions"
                  :value="item.slug"
                  :key="index"
                >{{ item.slug === 'other' ? item.label + '(可填写内容)' : item.label }}</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="!formData.is_exist_shroud">
            <a-form-item label="寿衣型号">
              <a-input
                style="width: 100%"
                placeholder="若无特别说明，请输入无"
                v-decorator="['shroud_model', {
                  rules: [
                    { required: true, message: '请输入寿衣型号' },
                    { max: 100, message: '最多100个字符' },
                  ]
                }]"
                autocomplete="off"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24" v-if="formData.is_exist_shroud">
            <a-form-item label="寿衣">
              {{ formData.shroud_info }}
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="!formData.is_exist_coffin">
            <a-form-item label="冰棺">
              <a-radio-group
                v-decorator="['coffin_type_slug', {
                  rules: [
                    { required: true, message: `请选择冰棺` }
                  ],
                }]"
              >
                <a-radio
                  v-for="(item, index) in coffinTypeOptions"
                  :value="item.slug"
                  :key="index"
                >{{ item.slug === 'other' ? item.label + '(可填写内容)' : item.label }}</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="!formData.is_exist_coffin">
            <a-form-item label="冰棺型号">
              <a-input
                style="width: 100%"
                placeholder="若无特别说明，请输入无"
                v-decorator="['coffin_model', {
                  rules: [
                    { required: true, message: '请输入冰棺型号' },
                    { max: 100, message: '最多100个字符' },
                  ]
                }]"
                autocomplete="off"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24" v-if="formData.is_exist_coffin">
            <a-form-item label="冰棺">
              {{ formData.coffin_info }}
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="!formData.is_exist_cinerary_casket">
            <a-form-item label="骨灰盒">
              <a-radio-group
                v-decorator="['cinerary_casket_type_slug']"
              >
                <a-radio
                  v-for="(item, index) in cineraryOptions"
                  :value="item.slug"
                  :key="index"
                >{{ item.slug === 'other' ? item.label + '(可填写内容)' : item.label }}</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="!formData.is_exist_cinerary_casket">
            <a-form-item label="骨灰盒型号">
              <a-input
                style="width: 100%"
                placeholder="若无特别说明，请输入无"
                v-decorator="['cinerary_casket_model']"
                autocomplete="off"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24" v-if="formData.is_exist_cinerary_casket">
            <a-form-item label="骨灰盒">
              {{ formData.cinerary_casket_info }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="大巴车">
              <a-radio-group
                v-decorator="['bus_type_slug', {
                  rules: [
                    { required: true, message: `请选择大巴车` }
                  ],
                }]"
              >
                <a-radio
                  v-for="(item, index) in busOptions"
                  :value="item.slug"
                  :key="index"
                >{{ item.label }}</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="大巴车备注">
              <a-input
                style="width: 100%"
                placeholder="若无特别说明，请输入无"
                v-decorator="['bus_remark', {
                  rules: [
                    { required: true, message: '请输入大巴车备注' },
                    { max: 100, message: '最多100个字符' },
                  ]
                }]"
                autocomplete="off"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="墓地">
              <a-input
                style="width: 100%"
                placeholder="若无特别说明，请输入无"
                v-decorator="['cemetery', {
                  rules: [
                    { required: true, message: '请输入墓地' },
                    { max: 100, message: '最多100个字符' },
                  ]
                }]"
                autocomplete="off"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="遗像">
              <a-radio-group
                v-decorator="['death_photo_type_slug', {
                  rules: [
                    { required: true, message: `请选择遗像` }
                  ],
                }]"
              >
                <a-radio
                  v-for="(item, index) in deathPhotoOptions"
                  :value="item.slug"
                  :key="index"
                >{{ item.label }}</a-radio>
              </a-radio-group>
              <a-input-number
                v-decorator="['death_photo_price']"
                :min="0"
                :precision="2"
                :step="0.01"
                :max="9999"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="!formData.is_exist_spa">
            <a-form-item label="SPA">
              <a-input
                style="width: 100%"
                placeholder="若无特别说明，请输入无"
                v-decorator="['spa', {
                  rules: [
                    { required: true, message: '请输入SPA' },
                    { max: 100, message: '最多100个字符' },
                  ]
                }]"
                autocomplete="off"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="formData.is_exist_spa">
            <a-form-item label="SPA">
              {{ formData.spa_info }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="佛事">
              <a-radio-group
                v-decorator="['buddhist_ceremony_type_slug']"
              >
                <a-radio
                  v-for="(item, index) in buddhistOptions"
                  :value="item.slug"
                  :key="index"
                >{{ item.label }}</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="抽身布">
              <a-input
                style="width: 100%"
                placeholder="若无特别说明，请输入无"
                v-decorator="['pull_out_cloth', {
                  rules: [
                    { required: true, message: '请输入抽身布' },
                    { max: 100, message: '最多100个字符' },
                  ]
                }]"
                autocomplete="off"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="是否用供菜">
              <a-radio-group
                v-decorator="['offering_vegetables_fee']"
              >
                <a-radio :value="0">0元</a-radio>
                <a-radio :value="100">100元</a-radio>
                <a-radio :value="200">200元</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="死亡证明及销户办理">
              <a-input
                style="width: 100%"
                placeholder="若无特别说明，请输入无"
                v-decorator="['death_certificate_account_cancellation', {
                  rules: [
                    { required: true, message: '请输入死亡证明及销户办理' },
                    { max: 100, message: '最多100个字符' },
                  ]
                }]"
                autocomplete="off"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12" :offset="12" />
          <a-col :span="12">
            <a-form-item label="骨灰盒安葬后是否回园区">
              <a-radio-group
                v-decorator="['back_park', {
                  rules: [
                    { required: true, message: `请选择骨灰盒安葬后是否回园区` }
                  ],
                }]"
              >
                <a-radio :value="true">是</a-radio>
                <a-radio :value="false">否</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="备注">
              <a-input
                style="width: 100%"
                placeholder="若无特别说明，请输入无"
                v-decorator="['remark', {
                  rules: [
                    { required: true, message: '请输入备注' },
                    { max: 100, message: '最多100个字符' },
                  ]
                }]"
                autocomplete="off"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="谈单时间">
              <a-date-picker
                style="width: 100%;"
                format="YYYY-MM-DD HH:mm"
                :show-time="{ format: 'HH:mm' }"
                placeholder="请选择时间"
                v-decorator="['negotiation_time', {
                  rules: [
                    { required: true, message: `请选择谈单时间` }
                  ],
                }]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="订单来源">
              <a-input
                style="width: 100%"
                placeholder="若无特别说明，请输入无"
                v-decorator="['order_source', {
                  rules: [
                    { required: true, message: '请输入订单来源' },
                    { max: 100, message: '最多100个字符' },
                  ]
                }]"
                autocomplete="off"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="接运地点">
              {{ serviceOrder.origination_info? serviceOrder.origination_info.origination:"" }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="介绍人">
              <a-input
                style="width: 100%"
                placeholder="若无特别说明，请输入无"
                v-decorator="['introducer', {
                  rules: [
                    { required: true, message: '请输入介绍人' },
                    { max: 100, message: '最多100个字符' },
                  ]
                }]"
                autocomplete="off"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="业务洽谈人员">
              <a-input
                style="width: 100%"
                placeholder="若无特别说明，请输入无"
                v-decorator="['negotiator', {
                  rules: [
                    { required: true, message: '请输入业务洽谈人员' },
                    { max: 100, message: '最多100个字符' },
                  ]
                }]"
                autocomplete="off"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </a-row>
</template>

<script>

import {
  findConfirmFuneralHandover,
  findOrderInfo, findAuditoriumServiceForm, createOrUpdateAuditoriumService
} from '@/api/order'
import { formatDateTimeMin } from '@/utils/time'
import {
  findAuditoriumServiceBusTypeOptions,
  findAuditoriumServiceBuddhistTypeOptions,
  findAuditoriumServiceCoffinTypeOptions,
  findAuditoriumServiceDaiHaoOptions,
  findAuditoriumServiceDeathPhotoTypeOptions,
  findAuditoriumServicePoliticalOutlookOptions,
  findAuditoriumServiceShroudTypeOptions,
  findAuditoriumServiceCineraryTypeOptions,
  findAuditoriumServiceSuitcaseTypeOptions
} from '@/api/auditorium_service'

export default {
  name: 'ConfirmAuditoriumService',
  components: {
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'confirm_auditorium_service' }),
      submitting: false,
      data: {},
      serviceOrder: {},
      deathInfo: {},
      politicalOutlookOptions: [], // 政治面貌选贤
      daiHaoOptions: [], // 戴号选项
      coffinTypeOptions: [], // 棺材选项
      cineraryOptions: [], // 骨灰盒选项
      shroudOptions: [], // 寿衣选项
      buddhistOptions: [], // 大巴和佛事选项
      busOptions: [], // 大巴选项
      deathPhotoOptions: [], // 遗像选项
      handover: {},
      isShowPartyFlag: false, // 是否显示党旗
      formData: {},
      isNeedPartyFlag: false,
      auditoriumServiceID: 0,
      suitcaseTypeOptions: [] // 风俗选项
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchFormData()
    this.fetchServiceOrderInfo()
    this.fetchPoliticalOutlookOptions()
    this.fetchDaiHaoOptions()
    this.fetchCoffinTypeOptions()
    this.fetchShroudOptions()
    this.fetchCineraryOptions()
    this.fetchbuddhistOptions()
    this.fetchBusOptions()
    this.fetchDeathPhotoOptions()
    this.fetchHandoverData()
    this.fetchSuitcaseTypeOptions()
  },
  methods: {
    fetchServiceOrderInfo() {
      findOrderInfo(this.id).then(res => {
        if (res.code === 0) {
          this.serviceOrder = res.data
        }
      })
    },
    fetchPoliticalOutlookOptions() {
      findAuditoriumServicePoliticalOutlookOptions().then(res => {
        if (res.code === 0) {
          this.politicalOutlookOptions = res.data.result
        }
      })
    },
    fetchDaiHaoOptions() {
      findAuditoriumServiceDaiHaoOptions().then(res => {
        if (res.code === 0) {
          this.daiHaoOptions = res.data.result
        }
      })
    },
    fetchCoffinTypeOptions() {
      findAuditoriumServiceCoffinTypeOptions().then(res => {
        if (res.code === 0) {
          this.coffinTypeOptions = res.data.result
        }
      })
    },
    fetchCineraryOptions() {
      findAuditoriumServiceCineraryTypeOptions().then(res => {
        if (res.code === 0) {
          this.cineraryOptions = res.data.result
        }
      })
    },
    fetchShroudOptions() {
      findAuditoriumServiceShroudTypeOptions().then(res => {
        if (res.code === 0) {
          this.shroudOptions = res.data.result
        }
      })
    },
    fetchbuddhistOptions() {
      findAuditoriumServiceBuddhistTypeOptions().then(res => {
        if (res.code === 0) {
          this.buddhistOptions = res.data.result
        }
      })
    },
    fetchBusOptions() {
      findAuditoriumServiceBusTypeOptions().then(res => {
        if (res.code === 0) {
          this.busOptions = res.data.result
        }
      })
    },
    fetchDeathPhotoOptions() {
      findAuditoriumServiceDeathPhotoTypeOptions().then(res => {
        if (res.code === 0) {
          this.deathPhotoOptions = res.data.result
        }
      })
    },
    fetchSuitcaseTypeOptions() {
      findAuditoriumServiceSuitcaseTypeOptions().then(res => {
        if (res.code === 0) {
          this.suitcaseTypeOptions = res.data.result
        }
      })
    },

    fetchFormData() {
      const negotiationTime = this.$moment().format('YYYY-MM-DD HH:mm')
      findAuditoriumServiceForm(this.id).then(res => {
        if (res.code === 0) {
          if (res.data.political_outlook_slug === 'zhong_gong_dang_yuan') {
            this.isShowPartyFlag = true
          }
          this.isNeedPartyFlag = res.data.is_need_party_flag
          this.auditoriumServiceID = res.data.id
          this.data = {
            political_outlook_slug: res.data.political_outlook_slug,
            is_need_party_flag: res.data.is_need_party_flag,
            suitcase_type: res.data.suitcase_type === 'none' ? undefined : res.data.suitcase_type,
            is_huan_hong: res.data.id === 0 ? undefined : res.data.is_huan_hong,
            partner_status_slug: res.data.partner_status_slug,
            children_info: res.data.children_info,
            children_dai_hao: res.data.children_dai_hao,
            shroud_type_slug: res.data.shroud_type_slug === 'none' ? undefined : res.data.shroud_type_slug,
            shroud_model: res.data.shroud_model,
            coffin_type_slug: res.data.coffin_type_slug === 'none' ? undefined : res.data.coffin_type_slug,
            coffin_model: res.data.coffin_model,
            cinerary_casket_type_slug: res.data.cinerary_casket_type_slug,
            cinerary_casket_model: res.data.cinerary_casket_model,
            bus_type_slug: res.data.bus_type_slug === 'none' ? undefined : res.data.bus_type_slug,
            bus_remark: res.data.bus_remark,
            cemetery: res.data.cemetery,
            death_photo_type_slug: res.data.death_photo_type_slug === 'none' ? undefined : res.data.death_photo_type_slug,
            death_photo_price: res.data.death_photo_price,
            spa: res.data.spa,
            buddhist_ceremony_type_slug: res.data.buddhist_ceremony_type_slug,
            pull_out_cloth: res.data.pull_out_cloth,
            offering_vegetables_fee: res.data.id === 0 ? undefined : res.data.offering_vegetables_fee,
            death_certificate_account_cancellation: res.data.death_certificate_account_cancellation,
            back_park: res.data.id === 0 ? undefined : res.data.back_park,
            remark: res.data.remark,
            negotiation_time: res.data.negotiation_time !== '' ? this.$moment(res.data.negotiation_time, 'YYYY-MM-DD HH:mm') : this.$moment(negotiationTime, 'YYYY-MM-DD HH:mm'),
            order_source: res.data.order_source,
            introducer: res.data.introducer,
            negotiator: res.data.negotiator
          }
          this.form.setFieldsValue(this.data)
        }
      })
    },
    fetchHandoverData() {
      findConfirmFuneralHandover(this.id).then((res) => {
        if (res.code === 0) {
          this.handover = res.data
        }
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values,
            negotiation_time: formatDateTimeMin(values.negotiation_time),
            auditorium_service_id: this.auditoriumServiceID,
            is_need_party_flag: this.isNeedPartyFlag
          }
          createOrUpdateAuditoriumService(this.id, data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
