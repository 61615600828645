var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-row',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"礼厅服务交接","width":"1400px"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"custom-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 8 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"wrapper-col":{ span: 9 }}},[_c('div',{staticStyle:{"text-align":"center"}},[_vm._v("请与客户仔细沟通以下交接事项：")])]),_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"厅房名称"}},[_vm._v(" "+_vm._s(_vm.serviceOrder.hall_name? _vm.serviceOrder.hall_name:'')+" ")])],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"服务套餐"}},[_vm._v(" "+_vm._s(_vm.serviceOrder.package_name? _vm.serviceOrder.package_name:'')+" ")])],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"进出厅时间"}},[_vm._v(" "+_vm._s(((_vm.handover.to_hall_time? _vm.handover.to_hall_time:'') + "~" + (_vm.handover.out_hall_time? _vm.handover.out_hall_time:'')))+" ")])],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"逝者信息"}},[_vm._v(" "+_vm._s(((_vm.serviceOrder.death_name? _vm.serviceOrder.death_name:'') + "-" + (_vm.serviceOrder.sex_display? _vm.serviceOrder.sex_display:'') + "-" + (_vm.serviceOrder.age? _vm.serviceOrder.age:'')))+" ")])],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"政治面貌"}},[_c('a-row',[_c('a-col',{attrs:{"span":16}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['political_outlook_slug', {
                    rules: [
                      { required: true, message: "请选择政治面貌" }
                    ],
                  }]),expression:"['political_outlook_slug', {\n                    rules: [\n                      { required: true, message: `请选择政治面貌` }\n                    ],\n                  }]"}],on:{"change":function (e) {
                    if (e === 'zhong_gong_dang_yuan') {
                      _vm.isShowPartyFlag = true
                    } else {
                      _vm.isShowPartyFlag = false
                    }
                  }}},_vm._l((_vm.politicalOutlookOptions),function(option,index){return _c('a-select-option',{key:index,attrs:{"value":option.slug}},[_vm._v(" "+_vm._s(option.label)+" ")])}),1)],1),_c('a-col',{attrs:{"span":6,"offset":2}},[(_vm.isShowPartyFlag)?_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_need_party_flag']),expression:"['is_need_party_flag']"}],attrs:{"checked":_vm.isNeedPartyFlag},on:{"change":function (e) {
                    _vm.form.setFieldsValue({is_need_party_flag: e.target.checked})
                    _vm.isNeedPartyFlag = e.target.checked
                  }}},[_vm._v(" 党旗 ")]):_vm._e()],1)],1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"风俗"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['suitcase_type', {
                rules: [
                  { required: true, message: "请选择风俗" }
                ],
              }]),expression:"['suitcase_type', {\n                rules: [\n                  { required: true, message: `请选择风俗` }\n                ],\n              }]"}]},_vm._l((_vm.suitcaseTypeOptions),function(item,index){return _c('a-radio',{key:index,attrs:{"value":item.slug}},[_vm._v(_vm._s(item.label))])}),1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"是否换红"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_huan_hong', {
                rules: [
                  { required: true, message: "请选择是否换红" }
                ],
              }]),expression:"['is_huan_hong', {\n                rules: [\n                  { required: true, message: `请选择是否换红` }\n                ],\n              }]"}]},[_c('a-radio',{attrs:{"value":true}},[_vm._v("是")]),_c('a-radio',{attrs:{"value":false}},[_vm._v("否")])],1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"配偶情况"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['partner_status_slug', {
                rules: [
                  { required: true, message: "请选择配偶情况" }
                ],
              }]),expression:"['partner_status_slug', {\n                rules: [\n                  { required: true, message: `请选择配偶情况` }\n                ],\n              }]"}]},[_c('a-radio',{attrs:{"value":"living"}},[_vm._v("健在")]),_c('a-radio',{attrs:{"value":"dead"}},[_vm._v("不健在")]),_c('a-radio',{attrs:{"value":"none"}},[_vm._v("无配偶")])],1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"子女情况"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['children_info', {
                rules: [
                  { required: true, message: '请输入子女情况' },
                  { max: 100, message: '最多100个字符' } ]
              }]),expression:"['children_info', {\n                rules: [\n                  { required: true, message: '请输入子女情况' },\n                  { max: 100, message: '最多100个字符' },\n                ]\n              }]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"若无特别说明，请输入无","autocomplete":"off"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"子女戴号情况"}},[_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['children_dai_hao', {
                rules: [
                  { required: true, message: '请输入子女戴号情况' } ]
              }]),expression:"['children_dai_hao', {\n                rules: [\n                  { required: true, message: '请输入子女戴号情况' },\n                ]\n              }]"}],attrs:{"options":_vm.daiHaoOptions}})],1)],1),(!_vm.formData.is_exist_shroud)?_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"寿衣"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['shroud_type_slug', {
                rules: [
                  { required: true, message: "请选择寿衣" }
                ],
              }]),expression:"['shroud_type_slug', {\n                rules: [\n                  { required: true, message: `请选择寿衣` }\n                ],\n              }]"}]},_vm._l((_vm.shroudOptions),function(item,index){return _c('a-radio',{key:index,attrs:{"value":item.slug}},[_vm._v(_vm._s(item.slug === 'other' ? item.label + '(可填写内容)' : item.label))])}),1)],1)],1):_vm._e(),(!_vm.formData.is_exist_shroud)?_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"寿衣型号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['shroud_model', {
                rules: [
                  { required: true, message: '请输入寿衣型号' },
                  { max: 100, message: '最多100个字符' } ]
              }]),expression:"['shroud_model', {\n                rules: [\n                  { required: true, message: '请输入寿衣型号' },\n                  { max: 100, message: '最多100个字符' },\n                ]\n              }]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"若无特别说明，请输入无","autocomplete":"off"}})],1)],1):_vm._e(),(_vm.formData.is_exist_shroud)?_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"寿衣"}},[_vm._v(" "+_vm._s(_vm.formData.shroud_info)+" ")])],1):_vm._e(),(!_vm.formData.is_exist_coffin)?_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"冰棺"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['coffin_type_slug', {
                rules: [
                  { required: true, message: "请选择冰棺" }
                ],
              }]),expression:"['coffin_type_slug', {\n                rules: [\n                  { required: true, message: `请选择冰棺` }\n                ],\n              }]"}]},_vm._l((_vm.coffinTypeOptions),function(item,index){return _c('a-radio',{key:index,attrs:{"value":item.slug}},[_vm._v(_vm._s(item.slug === 'other' ? item.label + '(可填写内容)' : item.label))])}),1)],1)],1):_vm._e(),(!_vm.formData.is_exist_coffin)?_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"冰棺型号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['coffin_model', {
                rules: [
                  { required: true, message: '请输入冰棺型号' },
                  { max: 100, message: '最多100个字符' } ]
              }]),expression:"['coffin_model', {\n                rules: [\n                  { required: true, message: '请输入冰棺型号' },\n                  { max: 100, message: '最多100个字符' },\n                ]\n              }]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"若无特别说明，请输入无","autocomplete":"off"}})],1)],1):_vm._e(),(_vm.formData.is_exist_coffin)?_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"冰棺"}},[_vm._v(" "+_vm._s(_vm.formData.coffin_info)+" ")])],1):_vm._e(),(!_vm.formData.is_exist_cinerary_casket)?_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"骨灰盒"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['cinerary_casket_type_slug']),expression:"['cinerary_casket_type_slug']"}]},_vm._l((_vm.cineraryOptions),function(item,index){return _c('a-radio',{key:index,attrs:{"value":item.slug}},[_vm._v(_vm._s(item.slug === 'other' ? item.label + '(可填写内容)' : item.label))])}),1)],1)],1):_vm._e(),(!_vm.formData.is_exist_cinerary_casket)?_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"骨灰盒型号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['cinerary_casket_model']),expression:"['cinerary_casket_model']"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"若无特别说明，请输入无","autocomplete":"off"}})],1)],1):_vm._e(),(_vm.formData.is_exist_cinerary_casket)?_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"骨灰盒"}},[_vm._v(" "+_vm._s(_vm.formData.cinerary_casket_info)+" ")])],1):_vm._e(),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"大巴车"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['bus_type_slug', {
                rules: [
                  { required: true, message: "请选择大巴车" }
                ],
              }]),expression:"['bus_type_slug', {\n                rules: [\n                  { required: true, message: `请选择大巴车` }\n                ],\n              }]"}]},_vm._l((_vm.busOptions),function(item,index){return _c('a-radio',{key:index,attrs:{"value":item.slug}},[_vm._v(_vm._s(item.label))])}),1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"大巴车备注"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['bus_remark', {
                rules: [
                  { required: true, message: '请输入大巴车备注' },
                  { max: 100, message: '最多100个字符' } ]
              }]),expression:"['bus_remark', {\n                rules: [\n                  { required: true, message: '请输入大巴车备注' },\n                  { max: 100, message: '最多100个字符' },\n                ]\n              }]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"若无特别说明，请输入无","autocomplete":"off"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"墓地"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['cemetery', {
                rules: [
                  { required: true, message: '请输入墓地' },
                  { max: 100, message: '最多100个字符' } ]
              }]),expression:"['cemetery', {\n                rules: [\n                  { required: true, message: '请输入墓地' },\n                  { max: 100, message: '最多100个字符' },\n                ]\n              }]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"若无特别说明，请输入无","autocomplete":"off"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"遗像"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['death_photo_type_slug', {
                rules: [
                  { required: true, message: "请选择遗像" }
                ],
              }]),expression:"['death_photo_type_slug', {\n                rules: [\n                  { required: true, message: `请选择遗像` }\n                ],\n              }]"}]},_vm._l((_vm.deathPhotoOptions),function(item,index){return _c('a-radio',{key:index,attrs:{"value":item.slug}},[_vm._v(_vm._s(item.label))])}),1),_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['death_photo_price']),expression:"['death_photo_price']"}],attrs:{"min":0,"precision":2,"step":0.01,"max":9999}})],1)],1),(!_vm.formData.is_exist_spa)?_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"SPA"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['spa', {
                rules: [
                  { required: true, message: '请输入SPA' },
                  { max: 100, message: '最多100个字符' } ]
              }]),expression:"['spa', {\n                rules: [\n                  { required: true, message: '请输入SPA' },\n                  { max: 100, message: '最多100个字符' },\n                ]\n              }]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"若无特别说明，请输入无","autocomplete":"off"}})],1)],1):_vm._e(),(_vm.formData.is_exist_spa)?_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"SPA"}},[_vm._v(" "+_vm._s(_vm.formData.spa_info)+" ")])],1):_vm._e(),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"佛事"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['buddhist_ceremony_type_slug']),expression:"['buddhist_ceremony_type_slug']"}]},_vm._l((_vm.buddhistOptions),function(item,index){return _c('a-radio',{key:index,attrs:{"value":item.slug}},[_vm._v(_vm._s(item.label))])}),1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"抽身布"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['pull_out_cloth', {
                rules: [
                  { required: true, message: '请输入抽身布' },
                  { max: 100, message: '最多100个字符' } ]
              }]),expression:"['pull_out_cloth', {\n                rules: [\n                  { required: true, message: '请输入抽身布' },\n                  { max: 100, message: '最多100个字符' },\n                ]\n              }]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"若无特别说明，请输入无","autocomplete":"off"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"是否用供菜"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['offering_vegetables_fee']),expression:"['offering_vegetables_fee']"}]},[_c('a-radio',{attrs:{"value":0}},[_vm._v("0元")]),_c('a-radio',{attrs:{"value":100}},[_vm._v("100元")]),_c('a-radio',{attrs:{"value":200}},[_vm._v("200元")])],1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"死亡证明及销户办理"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['death_certificate_account_cancellation', {
                rules: [
                  { required: true, message: '请输入死亡证明及销户办理' },
                  { max: 100, message: '最多100个字符' } ]
              }]),expression:"['death_certificate_account_cancellation', {\n                rules: [\n                  { required: true, message: '请输入死亡证明及销户办理' },\n                  { max: 100, message: '最多100个字符' },\n                ]\n              }]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"若无特别说明，请输入无","autocomplete":"off"}})],1)],1),_c('a-col',{attrs:{"span":12,"offset":12}}),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"骨灰盒安葬后是否回园区"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['back_park', {
                rules: [
                  { required: true, message: "请选择骨灰盒安葬后是否回园区" }
                ],
              }]),expression:"['back_park', {\n                rules: [\n                  { required: true, message: `请选择骨灰盒安葬后是否回园区` }\n                ],\n              }]"}]},[_c('a-radio',{attrs:{"value":true}},[_vm._v("是")]),_c('a-radio',{attrs:{"value":false}},[_vm._v("否")])],1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark', {
                rules: [
                  { required: true, message: '请输入备注' },
                  { max: 100, message: '最多100个字符' } ]
              }]),expression:"['remark', {\n                rules: [\n                  { required: true, message: '请输入备注' },\n                  { max: 100, message: '最多100个字符' },\n                ]\n              }]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"若无特别说明，请输入无","autocomplete":"off"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"谈单时间"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['negotiation_time', {
                rules: [
                  { required: true, message: "请选择谈单时间" }
                ],
              }]),expression:"['negotiation_time', {\n                rules: [\n                  { required: true, message: `请选择谈单时间` }\n                ],\n              }]"}],staticStyle:{"width":"100%"},attrs:{"format":"YYYY-MM-DD HH:mm","show-time":{ format: 'HH:mm' },"placeholder":"请选择时间"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"订单来源"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['order_source', {
                rules: [
                  { required: true, message: '请输入订单来源' },
                  { max: 100, message: '最多100个字符' } ]
              }]),expression:"['order_source', {\n                rules: [\n                  { required: true, message: '请输入订单来源' },\n                  { max: 100, message: '最多100个字符' },\n                ]\n              }]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"若无特别说明，请输入无","autocomplete":"off"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"接运地点"}},[_vm._v(" "+_vm._s(_vm.serviceOrder.origination_info? _vm.serviceOrder.origination_info.origination:"")+" ")])],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"介绍人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['introducer', {
                rules: [
                  { required: true, message: '请输入介绍人' },
                  { max: 100, message: '最多100个字符' } ]
              }]),expression:"['introducer', {\n                rules: [\n                  { required: true, message: '请输入介绍人' },\n                  { max: 100, message: '最多100个字符' },\n                ]\n              }]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"若无特别说明，请输入无","autocomplete":"off"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"业务洽谈人员"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['negotiator', {
                rules: [
                  { required: true, message: '请输入业务洽谈人员' },
                  { max: 100, message: '最多100个字符' } ]
              }]),expression:"['negotiator', {\n                rules: [\n                  { required: true, message: '请输入业务洽谈人员' },\n                  { max: 100, message: '最多100个字符' },\n                ]\n              }]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"若无特别说明，请输入无","autocomplete":"off"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }